import { Link } from "gatsby"
import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./HeroSectionMvc.module.scss"

const HeroSectionMvc = () => {
  return (
    <section className={styles.section}>
      <Container>
        <Row className="align-items-center">
          <Col xl={12}>
            <h1 className={styles.mainheading}>
              Join us at MWC 2022, Barcelona
            </h1>
            <p className={styles.subheading}>
              Witness how we solve real-world business problems<br></br>
              through technology and innovative ideas from Feb 28 to March 3.
            </p>
            <p className={styles.hashtag}>#poweringpossibilites </p>
            <Link to="https://calendly.com/invozone">
              <Button className={` ${styles.btnhero} btn_white_border `}>
                Let's catch-up over coffee
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default HeroSectionMvc
