import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./ExhibitingMvc.module.scss"

const ExhibitingMvc = () => {
  return (
    <section className={styles.section}>
      <Container>
        <Row className="align-items-center">
          <Col xl={6}></Col>
          <Col xl={6}>
            <h2 className={styles.mainheading}>Exhibiting at MWC 2022</h2>
            <p className={styles.subheading}>
              Super excited to see you in Barcelona for thought-provoking
              sessions, coffee, and probably some catching up on the latest in
              the software development industry. We would love to hear and share
              about trending technologies that will impact the future of your IT
              infrastructure, right from
              <a className={styles.sthall} href="#!">
                {" "}
                Hall 1 Stand 1G30 Booth #1.
              </a>
            </p>
            <p className={styles.headingthird}>
              So join our team at MWC to explore solutions to redefine the
              future of your enterprise
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ExhibitingMvc
