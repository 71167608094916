import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import Image from "react-bootstrap/Image"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import artefy from "../../images/sliderMvc/artefy.png"
import artefylap from "../../images/sliderMvc/artefylap.png"
import eye from "../../images/sliderMvc/eye-spy-logo.png"
import eyelap from "../../images/sliderMvc/eyespy.png"
import merido from "../../images/sliderMvc/meridio-logo.png"
import meridolap from "../../images/sliderMvc/meridio.png"
import nym from "../../images/sliderMvc/nym-card-logo.png"
import nymlap from "../../images/sliderMvc/nym.png"
import * as styles from "./MvcSlider.module.scss"
import "./MvcSlider.scss"

const MvcSlider = () => {
  const settings = {
    autoplay: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    dotsClass: "button__barr",
    speed: 700,
    arrows: false,
    lazyLoad: "ondemand",
  }
  const truncate = (str, num) => {
    num = num || 150
    if (str.length > num) {
      return str.slice(0, num) + "..."
    } else {
      return str
    }
  }

  return (
    <div className={styles.mvcSlider}>
      <Container>
        <div className={styles.mvcInfo}>
          <h2>You Think. We Design. We Develop.</h2>
          <p>
            Enabling businesses to unlock their true power with trending
            technologies, & creative minds.
          </p>
        </div>
        <Slider {...settings}>
          <div className={styles.sliderContent}>
            <div className={styles.sliderdata}>
              <div className={styles.select}></div>
              <div className={styles.logo}>
                <Image
                  decoding="async"
                  loading="lazy"
                  src={merido}
                  alt="merido"
                  style={{ maxWidth: "180px", maxHeight: "120px" }}
                />
              </div>
              <div>
                <h3>Blockchain-based real estate platform</h3>
                <p>
                  {truncate(`  Meridio is a platform to create, manage, and trade fractional
                  ownership shares in real estate assets.`)}
                </p>
                <Link to="https://invozone.com/portfolio/meridio/">
                  Read case study
                </Link>
                <Link to="https://invozone.com/portfolio/">
                  {" "}
                  <button className="btn_black_border">
                    Innovation Portfolio
                  </button>
                </Link>
              </div>
            </div>
            <div className={styles.sliderImage}>
              <Image decoding="async" loading="lazy" src={meridolap} alt="meridolap" />
            </div>
          </div>
          <div className={styles.sliderContent}>
            <div className={styles.sliderdata}>
              <div className={styles.select}></div>
              <div className={styles.logo}>
                <Image
                  decoding="async"
                  loading="lazy"
                  src={artefy}
                  alt="artefy"
                  style={{ maxWidth: "180px", maxHeight: "120px" }}
                />
              </div>
              <div>
                <h3>NFT Marketplace Development</h3>
                <p>
                  {truncate(`Artefy is an NFT marketplace for buying, selling, and
                  collecting digital arts.`)}{" "}
                </p>
                <Link to="https://drive.google.com/drive/folders/1-BVayqYrfkdwjd6T5IRzsHpdUXnZ-Ox3">
                  Read case study
                </Link>
                <Link to="https://invozone.com/portfolio/">
                  {" "}
                  <button className="btn_black_border">
                    Innovation Portfolio
                  </button>
                </Link>
              </div>
            </div>
            <div className={styles.sliderImage}>
              <Image decoding="async" loading="lazy" src={artefylap} alt="artefylap" />
            </div>
          </div>
          <div className={styles.sliderContent}>
            <div className={styles.sliderdata}>
              <div className={styles.select}></div>
              <div className={styles.logo}>
                <img
                  decoding="async"
                  loading="lazy"
                  src={eye}
                  alt="eye"
                  style={{ maxWidth: "180px", maxHeight: "120px" }}
                />
              </div>
              <div>
                <h3>KYC SaaS Product Development</h3>
                <p>
                  {truncate(
                    ` EyeSpy provides KYC and identity verification solutions`
                  )}
                  .{" "}
                </p>
                <Link to="https://drive.google.com/file/d/1WdRu5KKORQkFDKozUvx-PwUdkxRicyTH/view">
                  Read case study
                </Link>
                <Link to="https://invozone.com/portfolio/">
                  {" "}
                  <button className="btn_black_border">
                    Innovation Portfolio
                  </button>
                </Link>
              </div>
            </div>
            <div className={styles.sliderImage}>
              <Image decoding="async" loading="lazy" src={eyelap} alt="eyelap" />
            </div>
          </div>
          <div className={styles.sliderContent}>
            <div className={styles.sliderdata}>
              <div className={styles.select}></div>
              <div className={styles.logo}>
                <Image
                  decoding="async"
                  loading="lazy"
                  src={nym}
                  alt="nym"
                  style={{ maxWidth: "180px", maxHeight: "120px" }}
                />
              </div>
              <div>
                <h3>Cloud-based card issuer/processor</h3>
                <p>
                  {truncate(`   NYMCARD is a cloud-based card issuer/ processor allowing
                  financial institutions to sculpt virtual and plastic card
                  programs for their customers.{" "}`)}
                </p>
                <Link to="https://drive.google.com/file/d/1FlNbVKORf0fJQcIUbXen9X_uqwQ7knWD/view">
                  Read case study
                </Link>
                <Link to="https://invozone.com/portfolio/">
                  {" "}
                  <button className="btn_black_border">
                    Innovation Portfolio
                  </button>
                </Link>
              </div>
            </div>
            <div className={styles.sliderImage}>
              <Image decoding="async" loading="lazy" alt="nymlap" src={nymlap} />
            </div>
          </div>
        </Slider>
      </Container>
    </div>
  )
}

export default MvcSlider
