import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./OurVision.module.scss"

const OurVision = () => {
  return (
    <section className={styles.section}>
      <Container>
        <Row className="align-items-center">
          <Col xl={12}>
            <p className={styles.headBlack}>
              <a className={styles.subheading} href="#!">
                Our vision
              </a>{" "}
              is to redefine how businesses grow, maximize their profitability,
              deliver consistent customer service and inspire digital change.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default OurVision
