import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Spinner from "react-bootstrap/Spinner"
import { encodeToFormData, isEmail } from "../../utils"

const ContactForm = ({ sourceUrl = "", classs, eBookName = "", slug = "" }) => {
  const [status, setStatus] = useState("init")

  const initValues = {
    fullName: "",
    email: "",
  }
  const [values, setValues] = useState(initValues)
  const [errors, setErrors] = useState({})

  const handleChange = e => {
    const { name, value } = e.target
    values[name] = value
    setValues({ ...values })

    if (errors.hasOwnProperty(name)) {
      delete errors[name]
      setErrors(errors)
    }
  }

  const validate = data => {
    const newErrors = {}
    for (const key in data) {
      const value = data[key]
      switch (key) {
        case "fullName":
        case "subject":
        case "message":
          if (value.trim() === "") newErrors[key] = "This field is required!"
          break
        case "email":
          if (value.trim() === "") newErrors[key] = "This field is required!"
          else if (!isEmail(value)) newErrors[key] = "Email must be valid!"

          break

        default:
          break
      }
    }
    return newErrors
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const validationErrors = validate(values)
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      return
    }
    setStatus("submitting")

    const url = `${process.env.GATSBY_STRAPI_API_URL}/api/contactusforms/?id=9008`
    const data = {
      ...values,
      eBookName,
    }
    const formData = encodeToFormData(data)
    try {
      const result = await fetch(url, {
        method: "POST",
        body: formData,
      }).then(res => {
        if (res.status === 429) {
          setStatus("failed")
          return
        }
        return res.json()
      })
      if (
        !result.hasOwnProperty("error") ||
        result.status === "mail_sent" ||
        result.status === "mail_failed"
      ) {
        setValues(initValues)
        setStatus("success")
        window.open(sourceUrl, "_blank")
      } else {
        throw new Error("Server error!")
      }
    } catch (err) {
      setStatus("failed")
    }
  }

  return (
    <Form name="Contact Us Form" method="POST" onSubmit={handleSubmit}>
      <Row className={`${classs}`}>
        <Col md={5}>
          <Form.Group controlId="name">
            <Form.Label className="font-weight-normal px-1">
              Full Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="fullName"
              value={values.fullName}
              onChange={handleChange}
              isInvalid={errors.fullName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.fullName}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={5}>
          <Form.Group controlId="email">
            <Form.Label className="font-weight-normal px-1">
              Email <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              // placeholder="Email address"
              name="email"
              value={values.email}
              onChange={handleChange}
              isInvalid={errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      {status === "failed" && status !== "submitting" && (
        <div className="my-3 text-danger">
          Oops! Something went wrong while submitting the form.
        </div>
      )}
      <Row className={`${classs}`}>
        <Col md={6} style={{ textAlign: classs ? "center" : null }}>
          <button
            type="submit"
            aria-label="Send"
            id={`ebook_${slug}`}
            disabled={status === "submitting"}
            className={`btn_white_border mt-2 w-75`}
          >
            {status === "submitting" ? (
              <>
                <Spinner
                  variant="dark"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="sr-only">Download eBook</span>
              </>
            ) : (
              "Download eBook"
            )}
          </button>
        </Col>
      </Row>
    </Form>
  )
}

export default ContactForm
