import React from "react"
import BirdEye from "../components/Mvc/BirdEye"
import ExhibitingMvc from "../components/Mvc/ExhibitingMvc"
import Focus from "../components/Mvc/Focus"
import FocusServicesMvc from "../components/Mvc/FocusServicesMvc"
import FunctionalPart from "../components/Mvc/FunctionalPart"
import HeroSectionMvc from "../components/Mvc/HeroSectionMvc"
import MvcSlider from "../components/Mvc/MvcSlider"
import OurVision from "../components/Mvc/OurVision"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"
export const Head = () => {
  return (
    <SEORevamp
      title="MWC 2022 - Event Page"
      description="Embark on innovation at Invozone Event, MWC 2022, Barcelona. Join us for groundbreaking tech discussions and networking. Don't miss out!"
    />
  )
}
const Mvcc = () => {
  return (
    <MainLayout bgChanged={false}>
      <HeroSectionMvc />
      <OurVision />
      <ExhibitingMvc />
      <FocusServicesMvc />
      <Focus />
      <MvcSlider />
      <BirdEye />
      <FunctionalPart />
    </MainLayout>
  )
}

export default Mvcc
