// extracted by mini-css-extract-plugin
export var box = "FocusServicesMvc-module--box--7fa7c";
export var btnprimary = "FocusServicesMvc-module--btnprimary--58f4e";
export var card = "FocusServicesMvc-module--card--14dec";
export var designation = "FocusServicesMvc-module--designation--18a56";
export var followUs = "FocusServicesMvc-module--followUs--54cec";
export var imgst = "FocusServicesMvc-module--imgst--8f291";
export var info = "FocusServicesMvc-module--info--70644";
export var mainheading = "FocusServicesMvc-module--mainheading--6c81d";
export var name = "FocusServicesMvc-module--name--cf018";
export var row = "FocusServicesMvc-module--row--f1ccd";
export var section = "FocusServicesMvc-module--section--764a8";