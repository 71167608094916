import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Image from "react-bootstrap/Image"
import Row from "react-bootstrap/Row"
import fucntional from "../../images/fucntional.png"
import PdfForm from "../e-bookPost-sections/downloadPdfForm"
import * as styles from "./FunctionalPart.module.scss"

const FunctionalPart = () => {
  return (
    <div className={styles.FunctionalPart}>
      <section className={styles.func}>
        <Container>
          <Row className="align-items-start gy-4">
            <Col lg={4} xs={12}>
              <div className={styles.funcImgae}>
                <Image
                  src={fucntional}
                  alt={"Image Mvc"}
                  decoding="async"
                  loading="lazy"
                />
              </div>
            </Col>
            <Col lg={8} xs={12}>
              <div className={styles.funcContent}>
                <h2>
                  Functional vs. Non-functional Requirements in Software
                  Development - A Definitive Guide
                </h2>
                <p>
                  You may wonder what is the difference between functional and
                  non-functional requirements and why does the difference matter
                  to begin with. We will discuss all this and more in this
                  guide.{" "}
                </p>
                <PdfForm
                  classs={styles.row}
                  sourceUrl={
                    "https://invozone-backend.s3.amazonaws.com/Functional_vs_Non_Functional_Requirements_02cfafa382.pdf"
                  }
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default FunctionalPart
