import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Image from "react-bootstrap/Image"
import Row from "react-bootstrap/Row"
import rightArr from "../../images/svg/rightArr.svg"
import p4 from "../../images/p1.png"
import p3 from "../../images/p2.png"
import p2 from "../../images/p3.png"
import p1 from "../../images/p4.png"
import * as styles from "./BirdEye.module.scss"

const BirdEye = () => {
  return (
    <div className={styles.birdEye}>
      <Container>
        <div className={styles.birdEyeInfo}>
          <h2>The birds eye view</h2>
          <p>
            Latest in the software development industry that you need to know
            right now.
          </p>
        </div>
        <Row className="align-item-center">
          <Col lg={6} md={12} className={styles.col6s}>
            <Link
              to={
                "https://invozone.com/blog/how-metaverse-can-transform-fintech/"
              }
            >
              <div className={styles.mainCard}>
                <Image src={p1} alt={"Image Blog"} />
                <div className={styles.cardBody}>
                  <strong>FINTECH</strong>
                  <h4>How Metaverse can transform FINTECH?</h4>
                  <p>
                    Metaverse is not an altogether new concept. The past year
                    has transformed social media along with social behaviors ...
                  </p>
                </div>
              </div>
            </Link>
          </Col>
          <Col lg={6} md={12}>
            <div className={styles.cardList}>
              <Link to={"/blog/nft-nft-everywhere-lets-find-out-why/"}>
                <div className={styles.cards}>
                  <Image src={p2} alt="blog image" />
                  <div className={styles.cardContent}>
                    <strong>NFT</strong>
                    <h4>NFT,NFT everywhere lets find out why?</h4>
                  </div>
                </div>
              </Link>
              <Link
                to={
                  "/blog/is-mark-zuckerbergs-dream-of-the-metaverse-realistic/"
                }
              >
                <div className={styles.cards}>
                  <Image src={p3} alt="blog image" />
                  <div className={styles.cardContent}>
                    <strong>Metaverse</strong>
                    <h4>Is Mark Zuckerberg’s dream of Metaverse realisitc?</h4>
                  </div>
                </div>
              </Link>
              <Link to="https://invozone.com/blog/6-use-cases-of-artificial-intelligence-in-telecommunication-industry/">
                <div className={styles.cards}>
                  <Image src={p4} alt="blog image" />
                  <div className={styles.cardContent}>
                    <strong>AI </strong>
                    <h4>
                      6 Use cases of artificial intelligence in
                      telecommunication industry?
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
          </Col>
          <Col xs={12}>
            <Link to={"https://invozone.com/blog/"} className={styles.readMore}>
              Our blog archives
              <img
                src={rightArr}
                decoding="async"
                loading="lazy"
                className="ml-1"
                alt="icon"
              />
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default BirdEye
