import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import file1 from "../../images/files1.png"
import file2 from "../../images/files2.png"
import file3 from "../../images/files3.png"
import file5 from "../../images/image_161.png"
import * as styles from "./Focus.module.scss"

const Focus = () => {
  return (
    <div className={styles.focus}>
      <Container>
        <div className={styles.focusinfo}>
          <h2>We focus on all that is surreal & futuristic</h2>
        </div>
        <Row className="align-items-center" decoding="async" loading="lazy">
          <Col lg={3} md={6} xs={12}>
            <div
              className={styles.cardBg}
              style={{ background: `url(${file1})` }}
            >
              <Link className={styles.cardBgButton}>FinTech</Link>
            </div>
          </Col>
          <Col lg={3} md={6} xs={12}>
            <div
              className={styles.cardBg}
              style={{ background: `url(${file2})` }}
            >
              <Link className={styles.cardBgButton}>BlockChain</Link>
            </div>
          </Col>
          <Col lg={3} md={6} xs={12}>
            <div
              className={styles.cardBg}
              style={{ background: `url(${file3})` }}
            >
              <Link className={styles.cardBgButton}>IOT</Link>
            </div>
          </Col>
          <Col lg={3} md={6} xs={12}>
            <div
              className={styles.cardBg}
              style={{ background: `url(${file5})` }}
            >
              <Link className={styles.cardBgButton}>AI</Link>
            </div>
          </Col>
        </Row>
        <div className={styles.btncen}>
          <Link to="https://invozone.com/software-development-services/">
            <button className="btn_black_border">Our focus areas</button>
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default Focus
