import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import rightArr from "../../images/svg/rightArr.svg"
import linkedin from "../../images/social-icons/linkedin.svg"
import twitter from "../../images/react-icons/twitter-blue-logo.svg"
import asfand from "../../images/zs1.png"
import nael from "../../images/zs2.png"
import umair from "../../images/zs3.png"
import sirfurqan from "../../images/zs4.png"
import naveed from "../../images/zs5.png"
import * as styles from "./FocusServicesMvc.module.scss"

const FocusServicesMvc = () => {
  return (
    <section className={styles.section}>
      <Container>
        <Row className={`align-items-center ${styles.row}`}>
          <Col lg={4} md={6} xs={12}>
            <div className={styles.info}>
              <h2>
                Create connections <br></br>at MWC
              </h2>
              <p>with our team to identify the untapped</p>
              <Link to="https://invozone.com/about-us/">
                Get to know us
                <img
                  src={rightArr}
                  decoding="async"
                  loading="lazy"
                  className="ml-1"
                  alt="icon"
                />
              </Link>
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div className={styles.card}>
              <img
                className={styles.imgst}
                src={sirfurqan}
                alt="Sir Furqan"
                placeholder="blurred"
                decoding="async"
                loading="lazy"
              />
              <div className={styles.box}>
                <p className={styles.name}>Furqan Aziz</p>
                <p className={styles.designation}>CEO</p>

                <div className={styles.followUs}>
                  <a
                    href="https://twitter.com/furqanaziz786"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Follow us on Twitter"
                    className="btn btn-primary"
                  >
                    <img
                      src={twitter}
                      decoding="async"
                      loading="lazy"
                      alt="twitter"
                      style={{ width: "15px", height: "15px" }}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/furqan-aziz/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Follow us on LinkedIn"
                    className="btn btn-primary"
                  >
                    <img
                      src={linkedin}
                      decoding="async"
                      loading="lazy"
                      alt="linkedin"
                    />
                  </a>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div className={styles.card}>
              <img
                className={styles.imgst}
                src={nael}
                alt="Sir nael"
                placeholder="blurred"
                decoding="async"
                loading="lazy"
              />
              <div className={styles.box}>
                <p className={styles.name}>Nael Shahbaz</p>
                <p className={styles.designation}>Head of Marketing</p>

                <div className={styles.followUs}>
                  <a
                    href="https://twitter.com/NaelShahbaz"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Follow us on Twitter"
                    className="btn btn-primary"
                  >
                    <img
                      src={twitter}
                      decoding="async"
                      loading="lazy"
                      alt="twitter"
                      style={{ width: "15px", height: "15px" }}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/naelshahbaz/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Follow us on LinkedIn"
                    className="btn btn-primary"
                  >
                    <img
                      src={linkedin}
                      decoding="async"
                      loading="lazy"
                      alt="linkedin"
                    />
                  </a>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div className={styles.card}>
              <img
                className={styles.imgst}
                src={asfand}
                alt="Sir Asfand"
                placeholder="blurred"
                decoding="async"
                loading="lazy"
              />
              <div className={styles.box}>
                <p className={styles.name}>Asfand Yarr</p>
                <p className={styles.designation}>Head of BD</p>

                <div className={styles.followUs}>
                  <a
                    href="https://twitter.com/RafiqueAsfand"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Follow us on Twitter"
                    className="btn btn-primary"
                  >
                    <img
                      src={twitter}
                      decoding="async"
                      loading="lazy"
                      alt="twitter"
                      style={{ width: "15px", height: "15px" }}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/asfand-yarr/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Follow us on LinkedIn"
                    className="btn btn-primary"
                  >
                    <img
                      src={linkedin}
                      decoding="async"
                      loading="lazy"
                      alt="linkedin"
                    />
                  </a>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div className={styles.card}>
              <img
                className={styles.imgst}
                src={umair}
                alt="Sir Umair"
                placeholder="blurred"
                decoding="async"
                loading="lazy"
              />
              <div className={styles.box}>
                <p className={styles.name}>Umair Qureshi</p>
                <p className={styles.designation}>Head of Compliance</p>

                <div className={styles.followUs}>
                  <a
                    href="https://twitter.com/UmairQu37227120"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Follow us on Twitter"
                    className="btn btn-primary"
                  >
                    <img
                      src={twitter}
                      decoding="async"
                      loading="lazy"
                      alt="twitter"
                      style={{ width: "15px", height: "15px" }}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/umair-qureshi-a071712a/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Follow us on LinkedIn"
                    className="btn btn-primary"
                  >
                    <img
                      src={linkedin}
                      decoding="async"
                      loading="lazy"
                      alt="linkedin"
                    />
                  </a>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div className={styles.card}>
              <img
                className={styles.imgst}
                src={naveed}
                alt="Sir Naveed"
                placeholder="blurred"
                decoding="async"
                loading="lazy"
              />
              <div className={styles.box}>
                <p className={styles.name}>Naveed ul Aziz</p>
                <p className={styles.designation}>
                  Head of Digital Transformation
                </p>

                <div className={styles.followUs}>
                  <a
                    href="https://twitter.com/chhnaveedulaziz"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Follow us on Twitter"
                    className="btn btn-primary"
                  >
                    <img
                      src={twitter}
                      decoding="async"
                      loading="lazy"
                      alt="twitter"
                      style={{ width: "15px", height: "15px" }}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/naveed-ul-aziz/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Follow us on LinkedIn"
                    className="btn btn-primary"
                  >
                    <img
                      src={linkedin}
                      decoding="async"
                      loading="lazy"
                      alt="linkedin"
                    />
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default FocusServicesMvc
